import React from 'react';

import './styles.scss';

function AHeader( props ) {

    /*

    return(
        <>
            <div className="acontainer-header">
                
            </div>
        </>
    );
    
    */

    //*
    return(
        <>
            <div className="acontainer-header">
                <header className="common-header" >
                    <h2>
                        {props.title}
                    </h2>          
                </header>            
            </div>
        </>
    ); //*/

}


export default AHeader;