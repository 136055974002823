import React, { useState, useEffect } from 'react';
import AHeader from '../../../components/Header';
import ButtonBar from '../../../components/ButtonBar';

import AInput from '../../../components/AInput';
import ADate from '../../../components/ADate';
import ACheckBox from '../../../components/ACheckBox';

import imgSearch from '../../../image/24/Search.png';

import './styles.scss';

import { api } from '../../../service/api';
import ASelect from '../../../components/ASelect';
import { FormatCNPJ, FormatCEP, FormatPhone } from '../../../utils/formatter';
import { StatesBrazil } from '../../../utils/statesBrazil';
import { ValidateForm, ValidateCNPJ } from '../../../utils/FormUtils';
import { environment } from '../../../settings/environment.prod';
import ADialog from '../../../components/ADialog';

function BranchOfficeEdit( props ) {

    const urn = 'filiais';

    const { id } = props.match.params;
    const [ foundCNPJWS, setFoundCNPJWS ] = useState(false);

    const [data, setData] = useState({});    
    useEffect( () => {

        async function fetchData() {

            const response = await api.get( `/${urn}/findId/${id}` );   
            
            if ( response.data && Object.keys( response.data ).length > 0  ) {

                setData( response.data ); 

            } 
           
        }
         
        fetchData();
        
        return () => {
            setData({});
        }

    }, [id] );  

    const [ showDialog, setShowDialog ] = useState({ show: false, message: ''});

    const [Companies, setCompanies] = useState([]);
    useEffect( () => {

        async function fetchData() {

            const response = await api.get( 'empresas/findAll' ); 

            if ( response.data && Object.keys( response.data ).length > 0  ) {

                setCompanies( response.data );

            }

        }

        fetchData();
        
        return () => {

            setCompanies([]);

        }

    }, []);
    

    const updateState = e =>  {

        var value = undefined;

        if ( e.target.type === 'checkbox'  ) {

            if ( e.target.checked )
                value = 1
            else   
                value = 0;

        } else {
            value = e.target.value;
        }   

        setData({
            ...data, [e.target.name]: value 
        }); 

    }

    const handleInputCNPJ = e => {
        e.target.value = FormatCNPJ( e.target.value );
    }

    const handleBlurCNPJ = async e => {

        if ( !foundCNPJWS ) {

            let cnpj = e.target.value;

            if ( cnpj && cnpj.length > 0 )
                cnpj = cnpj.replace(/\D/g,"")

            if ( id === undefined ) { // se for novo inserção verifica se o cnpj já existe e carrega os dados

                const response = await api.get( `/filiais/find/${cnpj}` ); 

                if ( response.data !== null )
                    setData( response.data )
                else    
                    findBranchOfficeByCNPJ( cnpj );

            }

        }

    }

    const handleInputCEP = e => {
        e.target.value = FormatCEP( e.target.value );
    }
    const handleInputFone = e => {
        e.target.value = FormatPhone( e.target.value );
    }

    const save = async () => {

        if ( data.as_filId === undefined || data.as_filId === 0 ) {

            await api.post( urn, data );

        } else {

            await api.put( `${urn}/${data.as_filId}`, data );

        }

    }         

    const handleSave = async ( e ) => {

        e.persist();
        e.preventDefault();
       
        if ( validateForm( e.target ) === true ) {
            await save();      
            props.history.goBack();
        }   
        
    } 

    const validateForm = form => {
        
        if ( data.as_filCnpj  ) {

            if ( !ValidateCNPJ( data.as_filCnpj ) ) {

                setShowDialog({ 
                    ...showDialog, 
                        show    : true,
                        message : 'CNPJ Inválido.',
                }); 

                return false;

            }

        }

        const validate = ValidateForm( form );        
        let ele = undefined;

        if ( !validate.frmValid ) { 
            
            ele = validate.element;

            setShowDialog({ 
                ...showDialog, 
                    show    : true,
                    message : validate.message,
            }); 

        }

        if ( ele )
            document.getElementById(ele.id).focus();

        return validate.frmValid;   

    }

    const handleCLose = e => {
        e.preventDefault();
        props.history.goBack();
    }

    const handleCloseDialog = () => {

        setShowDialog({ 
            ...showDialog, 
                show    : false,
                message : ''
        });        

    }

    const findBranchOfficeByCNPJ = async cnpj => {

        cnpj = cnpj.replace(/\D/g,"");

        try {

            const response = await api.get( `${environment.API_V1}/ws/empresa/${cnpj}` ); 
         
            if ( response.data.status === "ERROR" ) { // Comparação com dois iguais pois aqui pode vir número.
                throw new Error( response.data.message );
            }

            const branchOffice = response.data;
                
            if ( branchOffice && Object.keys( branchOffice ).length > 0  ) {

                let cep = '';

                if ( branchOffice.cep )
                    cep = branchOffice.cep.replace(/\D/g,"");

                setData({
                    ...data, as_filRazao     : branchOffice.nome,
                             as_filEndereco  : `${branchOffice.logradouro}, ${branchOffice.numero}`,
                             as_filCep       : FormatCEP( cep ),
                             as_filBairro    : branchOffice.bairro,
                             as_filCidade    : branchOffice.municipio,
                             as_filUf        : branchOffice.uf,
                             as_filCompl     : branchOffice.complemento,
                             as_filFonePrinc : FormatPhone( branchOffice.telefone )
                });

                setFoundCNPJWS( true );

            }

        } catch( err ) {

            let msg = err.message;

            if ( msg.includes( 'code 429') ) {
                msg = 'Excedeu o limite de 3 consultas por minuto no serviço da Receita Federal. Aguarde um minuto e consulte novamente.';
            }

            setShowDialog({ 
                ...showDialog, 
                    show    : true,
                    message : msg,
            }); 

        }


    }


    const handleSearchCNPJ = async e  => {
      
        e.persist();
        e.preventDefault();

        if ( !data.as_filCnpj || data.as_filCnpj.length < 1 ) return;

        findBranchOfficeByCNPJ( data.as_filCnpj );

    }    
    
    return (

        <>
            <form  onSubmit={handleSave} noValidate>
            <AHeader title={'Editar Filial'} />
                <ButtonBar 
                    buttonBarType={'Edit'} 
                    onClose={handleCLose}
                />                

                    <header className="header-title" >
                        <h3>Empresa</h3>                       
                        <hr/>  
                    </header> 
                    
                    <table className="grid-window" >
                        <tr className="row-window">
                            <td className='col-window' >
                                
                                <ASelect
                                    name={'as_filempId'} 
                                    keyname={'as_empId'}
                                    desc={'as_empRazao'}                                                                                 
                                    caption={'Empresa'} 
                                    data={data} 
                                    options={Companies}
                                    onChange={updateState}
                                    required={true}
                                />
                                
                            </td> 
                        </tr>
                    </table>
               
                    <header className="header-title" >
                        <h3>Filial</h3> 
                        <hr/>                       
                    </header> 
                        <table className="grid-window" >
                            <tr className="row-window">
                                <td className='col-window-cnpj'>
                                    <div className="input-image" >
                                        <div className="content-input-image">  
                                            <div>                                          
                                                <AInput 
                                                    name={'as_filCnpj'} 
                                                    type={'text'} 
                                                    caption={'CNPJ'} 
                                                    data={data} 
                                                    onChange={updateState}
                                                    onInput={handleInputCNPJ}
                                                    onBlur={(e) =>handleBlurCNPJ(e)}
                                                    minLength={18}
                                                    maxLength={18}
                                                    required={true}
                                                />  
                                            </div>                                          
                                            <button onClick={(e)=>handleSearchCNPJ(e)} >
                                                <img src={imgSearch} alt="" height="24px" width="24px"/>         
                                            </button>
                                        </div>
                                     </div>                                
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filRazao'} 
                                        type={'text'} 
                                        caption={'Razão Social'} 
                                        data={data} 
                                        onChange={updateState}
                                        minLength={3}
                                        maxLength={80}  
                                        required={true} 
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filInsc_Est'} 
                                        type={'text'} 
                                        caption={'Inscrição Estadual'} 
                                        data={data} onChange={updateState}
                                        maxLength={18}  
                                    />
                                </td>
                            </tr>  
                            <tr  className="row-window">                                
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filEndereco'} 
                                        type={'text'} 
                                        caption={'Endereço'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={120}  
                                        required={true}
                                    />
                                </td>                                                    
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filCep'} 
                                        type={'text'} caption={'CEP'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputCEP}
                                        minLength={9}
                                        maxLength={9}
                                        required={true}
                                    />
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filBairro'} 
                                        type={'text'} 
                                        caption={'Bairro'} 
                                        data={data} 
                                        onChange={updateState}                                        
                                        maxLength={40}  
                                        required={true}
                                    />
                                </td>                        
                            </tr>  
                            <tr className="row-window">
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filCidade'} 
                                        type={'text'} 
                                        caption={'Cidade'} 
                                        data={data} 
                                        onChange={updateState}                                        
                                        maxLength={40}  
                                        minLength={2}
                                        required={true} 
                                    />
                                </td>
                                <td className='col-window' >
                                    <ASelect
                                        name={'as_filUf'} 
                                        keyname={'as_filUf'}
                                        desc={'sigla'}                                                                                 
                                        caption={'UF'} 
                                        data={data} 
                                        options={StatesBrazil.UF}
                                        onChange={updateState}
                                        maxLength={2}  
                                        required={true}
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filCompl'} 
                                        type={'text'} 
                                        caption={'Complemento'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={200}  
                                    />
                                </td>
                            </tr> 
                            <tr className="row-window">
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filFonePrinc'} 
                                        type={'text'} 
                                        caption={'Telefone Principal'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputFone}
                                        minLength={13}
                                        maxLength={14}
                                        required={true} 
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filFone2'} 
                                        type={'text'} 
                                        caption={'Telefone'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputFone}
                                        minLength={14}
                                        maxLength={14}
                                    />
                                </td>
                            </tr>  
                            <tr className="row-window">
                                <td className='col-window' >
                                    <ADate 
                                        name={'as_filValidade'}                                         
                                        caption={'Validade'} 
                                        data={data} 
                                        onChange={updateState}
                                        required={true}
                                    />
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_filQtdLicencas'} 
                                        type={'number'} 
                                        caption={'Qtd. Licenças'} 
                                        data={data} 
                                        onChange={updateState}
                                        required={true}
                                    />
                                </td>  
                                <td className='col-window' >
                                    <ACheckBox 
                                        name={'as_filAtivo'} 
                                        caption={'Ativo'} 
                                        data={data} 
                                        onChange={updateState}
                                    />
                                </td>                        
                            </tr>
                        </table>               
               
            </form>
            { 
                showDialog.show &&
                <ADialog 
                    message={showDialog.message} 
                    onClose={handleCloseDialog}
                />
            }

        </>
        
    );//*/

}

export default BranchOfficeEdit;