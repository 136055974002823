import React from 'react';
import AMainHeader from './components/AMainHeader';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/routes';

import './App.scss';

function App() {
    
    /*
    window.onkeydown = function() {

        if ( window.event.keyCode === 13 ) {
            
            if ( window.event.target.tagName === 'SELECT' ) {
                window.event.returnValue = true;
            } else {
                window.event.returnValue = false;
            }

        }
        
    }

    window.onunload = function(e) {

        logout();
       
    }

    */

    return (

        <>
            <BrowserRouter>
                <AMainHeader />
                <Routes />    
            </BrowserRouter>
        </>
        
    );


}

export default App;


/*

background 

#3f486c
#ff8502

*/