import React from 'react';

import './styles.scss';


export default function ASelect( props ) {

    const { name, keyname, desc, caption, maxLenght, minlenght, required, data, onChange, options, onBlur } = props;
    const value = data[name];

    return (
        
        <div className='select-content' >
            <label for={name}>{caption}</label>
            <select                 
                id={name} 
                name={name}
                minLength={minlenght} 
                maxLength={maxLenght} 
                required={required}
                onChange={onChange}
                onBlur={onBlur}

                value={value}
            >

            <option value=""></option>

            { options.map( item => (

                <option value={item[keyname]}>{item[desc]}</option>    

            )) }
               
            </select>

        </div> 
    );

}