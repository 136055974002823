import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompanyGroupBrowser from '../pages/company-group/browser/index';
import CompanyGroupEdit from '../pages/company-group/edit';

export default function CompanyGroupRouter() {

    return (

        <Switch>
            <Route exact path="/app/grupoempresas" component={CompanyGroupBrowser} />
            <Route path="/app/grupoempresas/new" component={CompanyGroupEdit} />
            <Route path="/app/grupoempresas/:id" component={CompanyGroupEdit} />            
        </Switch>
        
    )

}