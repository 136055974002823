import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import CompanyRouter from './company.routes';
import CompanyGroupRouter from './company-group.routes';
import BranchOfficeRouter from './branch-office.routes';
import Initial from '../pages/initial';
import HomeRouter from './home.routes';
import { isAuthenticated } from '../service/auth';
import AuditRouter from './audit.routes';


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : ( 
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

export default function Routes(props) {

    return (

        <>
            <Route exact path="/" component={Initial} />  
            <PrivateRoute path="/app" component={() => <></>} />          
            <HomeRouter />
            <CompanyRouter />
            <CompanyGroupRouter />
            <BranchOfficeRouter />
            <AuditRouter />
        </>
    
    );

}