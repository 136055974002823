export default class DateUtils {

    static formatDateTime( propDate ) {  // propDate - propriedade do objeto cujo valor é uma data no formato '2019-06-05T16:13:03.644Z'

        if ( propDate === undefined ) return undefined; 

        const formatter = new Intl.DateTimeFormat('pt-BR', { 
                            year: 'numeric', 
                            month: 'numeric', 
                            day: '2-digit',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour24: true,
                            timeZone: 'UTC'
                        });
                        
        return formatter.format( new Date(propDate) );

    }


    static formatJSON( dateString ) {  
        
        let arrDate = dateString.split('/');

        let date = new Date( arrDate[1] + '-' + arrDate[0] + '-' + arrDate[2] );

        let dateJSON = JSON.stringify( date );
        /*
        if ( dateJSON !==  'null' ) {    

            const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
            date.setHours(hoursDiff);

            dateJSON = date.toISOString();

        } */

        return dateJSON;

    }


    static formatMilliseconds( dateString ) {

        let arrDate = dateString.split('/');

        return Date.parse(arrDate[1] + '-' + arrDate[0] + '-' + arrDate[2]);

    }

} 