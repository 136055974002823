import React from 'react';
import { withRouter } from 'react-router-dom';

import ABrowser from '../../../components/ABrowser';

function CompanyGroupBrowser( props ) {

    let columns = [];

    const createSpecColumn = ( name, title, dataType, idData = '', idTitle = '' ) => {
        return { name: name, title: title, dataType: dataType, idData: idData, idTitle: idTitle };
    }

    columns.push( createSpecColumn( 'as_gpeDescricao', 'Descrição', 'string'  ) );  
    columns.push( createSpecColumn( 'as_gpeAtivo', 'Ativo', 'boolean' ) );

    return (

        <>
            <ABrowser
                urn='grupoEmpresas' 
                title='Grupo de Empresas' 
                keyname='as_gpeId' 
                columns={columns}
            />
        </>
    )


}

export default withRouter( CompanyGroupBrowser );