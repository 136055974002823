import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BranchOfficeBrowser from '../pages/branch-office/browser/index';
import BranchOfficeEdit from '../pages/branch-office/edit';

export default function BranchOfficeRouter() {

    return (

        <Switch>
            <Route exact path="/app/filiais" component={BranchOfficeBrowser} />
            <Route path="/app/filiais/new" component={BranchOfficeEdit} />
            <Route path="/app/filiais/:id" component={BranchOfficeEdit} />            
        </Switch>
        
    )

}