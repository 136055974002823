import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Audit from '../pages/audit';

export default function AuditRouter() {

    return (

        <Switch>            
            <Route exact path="/app/audit" component={Audit} />
        </Switch>
        
    )

}