export function RemoveItemInArray( array, key, value ) {

    const index = FindElementIndex( array, key, value );

    if ( index === -1 ) 
        return;

    return array.splice( index, 1 );

}

export function FindElementIndex( array, key, value ) {

    return array.findIndex( ( element ) => {

        return element[ key ] === value;

    });

}

export function FindElement( array, key, value ){

    return array.find( ( element ) => {

        let el = null;

        if ( element[key] === value ) {
            el = element;
            return el;
        }

        return el;

    });

} 

export function FindObjectIndex( array, object ) {

    return array.findIndex( ( element ) => {

        return element === object;

    });

}

export function RemoveObject( array, object ) {

    const index = FindObjectIndex( array, object );

    if ( index === -1 ) 
        return;

    return array.splice( index, 1 );

}

export function FilterElement( array, object ) {

    return array.filter( ( element ) => {

        let found = false;
        const props = Object.keys( element );

        for ( var i = 0; i <= props.length -1; i++ ) {

            const propName = props[ i ];

            if ( propName === '__v' ) continue;

            let elePropValue = element[ propName ];
            let objPropValue = object[ propName ] ;

            if ( ( elePropValue !== undefined && elePropValue !== null ) &&
                 ( objPropValue !== undefined && objPropValue !== null ) ) {

                if ( typeof objPropValue === 'boolean' ) {

                    if ( typeof elePropValue === 'number' )
                        elePropValue = elePropValue === 1; // tem que usar dois == para que ele possa fazer a conversão de valores se necessário na hora de comparar

                    found = elePropValue === objPropValue;

                } else if ( typeof elePropValue === 'number' ) {

                    elePropValue = elePropValue.toString();
                    objPropValue = objPropValue.toString();
                    
                    found = elePropValue.indexOf( objPropValue  ) >= 0 

                } else {

                    //found = elePropValue.toLowerCase().indexOf( objPropValue.toLowerCase() ) >= 0;

                    elePropValue = elePropValue.toLowerCase();
                    objPropValue = objPropValue.toLowerCase();

                    found = elePropValue.indexOf( objPropValue  ) >= 0 

                }

                if ( found === false ) break;

            }

        }

        return found;

    });

}