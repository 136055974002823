import React from 'react';
import { Route, Switch } from 'react-router-dom';
import home from '../pages/home';

export default function HomeRouter() {

    return (

        <Switch>            
            <Route exact path="/app/home" component={home} />
        </Switch>
        
    )

}