import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import './css/main.scss';
import './css/util.scss';

import { api } from '../../service/api';
import { environment } from '../../settings/environment.prod';
import { TOKEN_KEY, login } from '../../service/auth';

function Initial( props ) {

	const [ loginData, setLoginData ] = useState({ username: '', pass: '' });

	const updateState = e => {

		let value = e.target.value;
  
        setLoginData({
            ...loginData, [e.target.name]: value 
		}); 		

	}

	const handleLogin = async e => {

		e.persist();
		e.preventDefault();

		const response = await api.post( `${environment.API_SERVER}:${environment.API_PORT}/auth/login`, loginData );

		if ( response && response.data ) {

			const token = response.data;
      
			localStorage.setItem( TOKEN_KEY, token );
			localStorage.setItem( 'username', loginData.username );

			login( token );

			props.history.push( '/app/home' );

		} else {

			localStorage.setItem( 'isAuthenticated', false );
			alert('Nome de usuário ou senha são inválido.');

		}

	}

	return (

		<>

			<div className="limiter">
				<div className="container-login100">
					<div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
						<form className="login100-form validate-form flex-sb flex-w">
							<span className="login100-form-title p-b-32">
								Login
							</span>

							<span className="txt1 p-b-11">
								Usuário
							</span>
							<div className="wrap-input100 validate-input m-b-36" data-validate = "Nome do usuário é obrigatório">
								<input className="input100" type="text" name="username" onChange={(e) => updateState(e)} />
								<span className="focus-input100"></span>
							</div>
							
							<span className="txt1 p-b-11">
								Senha
							</span>
							<div className="wrap-input100 validate-input m-b-12" data-validate = "Senha é Obrigatória">
								<span className="btn-show-pass">
									<i className="fa fa-eye"></i>
								</span>
								<input className="input100" type="password" name="pass" onChange={(e) => updateState(e)} />
								<span className="focus-input100"></span>
							</div>							

							<div className="container-login100-form-btn">
								<button class="login100-form-btn" onClick={(e)=>handleLogin(e)} >
									Entrar
								</button>
							</div>

						</form>
					</div>
				</div>
			</div>
		

			<div id="dropDownSelect1"></div>

		</>
	

	);

}

export default withRouter( Initial );

