import React from 'react';
import { withRouter } from 'react-router-dom';

import './styles.scss';

function Home( props ) {

    return( <></>);


}

export default withRouter( Home );
