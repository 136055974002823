import Add24 from '../image/24/Add.png';
import Back24 from '../image/24/Back.png';
import Cancel24  from '../image/24/Cancel-bw.png';
import Edit24 from '../image/24/Modify.png';
import Delete24 from '../image/24/Delete.png';
import Exit24 from '../image/24/Exit.png';
import Info24 from '../image/24/Info.png';
import Load24 from '../image/24/Load.png';
import Next24 from '../image/24/Next.png';
import Print24 from '../image/24/Print.png';
import Profile24 from '../image/24/Profile.png';
import Save24 from '../image/24/Save.png';
import Search24 from '../image/24/Search.png';
import Warning24 from '../image/24/Warning.png';
import LogoAmerica from '../image/logo/LogoAmericaSoft.jpg';

export const ImageAdd24 = Add24;
export const ImageBack24 = Back24;
export const ImageCancel24 = Cancel24;
export const ImageEdit24 = Edit24;
export const ImageDetele24 = Delete24;
export const ImageExit24 = Exit24;
export const ImageInfo24 = Info24;
export const ImageLoad24 = Load24;
export const ImageNext24 = Next24;
export const ImagePrint24 = Print24;
export const ImageProfile24 = Profile24;
export const ImageSave24 = Save24;
export const ImageSearch24 = Search24;
export const ImageWarming24 = Warning24;

export const ImageLogoAmerica = LogoAmerica;

