export function FormatCNPJ( cnpj ) {
 
    //Remove tudo o que não é dígito
    cnpj = cnpj.replace(/\D/g,"")

    //Coloca ponto entre o segundo e o terceiro dígitos
    cnpj = cnpj.replace(/^(\d{2})(\d)/,"$1.$2")

    //Coloca ponto entre o quinto e o sexto dígitos
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")

    //Coloca uma barra entre o oitavo e o nono dígitos
    cnpj = cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")

    //Coloca um hífen depois do bloco de quatro dígitos
    cnpj = cnpj.replace(/(\d{4})(\d)/,"$1-$2")
 
    return cnpj;
 
};

export function FormatCPF( cpf ) {

    cpf = cpf.replace(/\D/g,"")

    //Coloca um ponto entre o terceiro e o quarto dígitos
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")

    //Coloca um hífen entre o terceiro e o quarto dígitos
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")

    return cpf;

}

export function FormatCEP( cep ) {

    cep = cep.replace(/\D/g,"")

    //00000-000

    //Coloca um traço entre o quinto e sexto dígitos
    cep = cep.replace(/(\d{5})(\d)/,"$1-$2")

    return cep;

}

export function FormatPhone(fone) {

    //000-00000-0000

    fone = fone.replace(/\D/g,"")

    //Coloca um traço entre o terceiro e o quarto dígitos
    fone = fone.replace(/(\d{3})(\d)/,"$1-$2")

    //Coloca um traço entre o nono e o decimo dígitos
    fone = fone.replace(/(\d{4})(\d)/,"$1-$2")

    return fone;

}