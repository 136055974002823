export const ValidateForm = form => {

    let empty = false;
    let formValid = true;
    let msg = '';    
    let el = undefined;

    for ( let i = 0; i <= form.length -1; i++ ) {

        el = form[ i ];

        if ( el.required === true ) {

            if ( typeof el.value === 'boolean') continue;

            empty = ( el.value === undefined || el.value.trim().length < 1 ) 

            if ( empty ) {

                formValid = false;
                msg = 'Preechimento do campo é obrigatório';                
                break;

            } else if ( el.type === "text" ) {
            
                if ( el.value.trim().length < el.minLength ) {

                    formValid = false;
                    msg = `O campo precisa ter mais de ${el.minLength-1} caracteres.`;                    
                    break;

                }  if ( el.value.trim().length > el.maxLength ) {
        
                        formValid = false;
                        msg = `O campo precisa ter no máximo ${el.maxLength} caracteres.`;                        
                        break;
                }
    
            }  

        } else if ( el.type === "text" ) {
                    
            if ( el.value.trim().length > el.maxLength ) {

                formValid = false;
                msg = `O campo precisa ter no máximo ${el.maxLength} caracteres.`;                
                break;

            }

        }

    }

    if ( formValid === false )
        document.getElementById(el.id).focus();

    return { frmValid : formValid, message: msg, element : el };        

}

export const ValidateCNPJ = ( cnpj ) => {

    cnpj = cnpj.replace(/[^\d]+/g,'');

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring( 0, tamanho );
    let digitos = cnpj.substring( tamanho );
    let pos       = 0;
    let soma = 0;
    let resultado = 0;
 
    if( cnpj === '' ) return false;
     
    if ( cnpj.length !== 14 )
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if ( cnpj === '00000000000000' || 
         cnpj === '11111111111111' || 
         cnpj === '22222222222222' || 
         cnpj === '33333333333333' || 
         cnpj === '44444444444444' || 
         cnpj === '55555555555555' || 
         cnpj === '66666666666666' || 
         cnpj === '77777777777777' || 
         cnpj === '88888888888888' || 
         cnpj === '99999999999999' )
         return false;         

    pos = tamanho - 7;
    for ( let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt( digitos.charAt(0) )) 
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for ( let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt( digitos.charAt(1)))
          return false;
           
    return true;


}

export const ValidateCPF = ( cpf ) => {
    
    var Soma;
    var Resto;
    Soma = 0;
    if ( cpf === '00000000000' ) return false;
            
    for ( let i = 1; i <= 9; i++ ) Soma = Soma + parseInt( cpf.substring( i-1,  i ) ) * ( 11 - i );
        Resto = (Soma * 10) % 11;
       
    if ( (Resto === 10 ) || ( Resto === 11)) Resto = 0;

    if ( Resto !== parseInt( cpf.substring( 9, 10 ) ) ) return false;
       
    Soma = 0;
    for ( let i = 1; i <= 10; i++) Soma = Soma + parseInt( cpf.substring( i-1,  i ) ) * ( 12 - i );
    Resto = (Soma * 10) % 11;
    
    if (( Resto === 10 ) || ( Resto === 11 ))  Resto = 0;

    if ( Resto !== parseInt( cpf.substring( 10, 11 ) ) ) return false;

    return true;

}