const API_PORT = 36301;

export const environment = {
    production: false,
    API_SERVER: 'http://52.67.5.25',
    API_PORT: API_PORT,
    API_V1: `http://52.67.5.25:${API_PORT}/app`,
    URN_BASE: 'app',
    URI: 'http://52.67.5.25:36301',
    username: '',
    isAuthenticated: false
};