import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { api } from '../../service/api';

import AHeader from '../Header';
import ButtonBar from '../ButtonBar';

import DateUtils from '../../utils/DateUtils';

import './styles.scss';
import { FilterElement } from '../../utils/ArrayUtils';
import { ImageEdit24 } from '../../image/image24';
import { ImageSortArrowAsc16, ImageSortArrowDes16 } from '../../image/image16';

function ABrowser( props ) {

    const { urn, uri, title, keyname, columns } = props; //desestructure;

    var curCol = undefined;
    var nxtCol = undefined;
    var pageX = undefined;
    var nxtColWidth = undefined;
    var curColWidth = undefined;
    var fieldFilter = [];
    
    const [showFilter, setShowFilter]  = useState( false );
    const [findParam, setFindParam]   = useState({});

    const [dataOriginal, setDataOriginal] = useState([]); 
    const [order, setOrder] = useState(0); // 0 = crescente, 1 = decrescente

    const [filterParam, setFilterParam] = useState({});
    useEffect( () => {

        if ( Object.keys( filterParam ).length > 0 ) {            
            const newListData = FilterElement( dataOriginal, filterParam );
            // add new list off data filtred
            setData(newListData);
        } else {
            setData(dataOriginal);
        }

       // add filterParam in findParam to load data parametrized from data base
        setFindParam(filterParam);        

    }, [filterParam, dataOriginal]);

    //var filterParam = {};
    
    const [ data, setData ] = useState([]); 
    useEffect( () => {

        async function fetchData() {

            let response = undefined;

            if ( uri )
                response = await api.get( `/${uri}` );
            else    
                response = await api.get( `/${urn}/findAll` );

            setData( response.data ); 
            setDataOriginal( response.data );
           
        }
         
        fetchData();

        return () => {
            setData([]);
            setDataOriginal([]);
        }

    }, [urn, uri] ); 

    function handleMouseDown(e) {

        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;
        curColWidth = curCol.offsetWidth;
        
        if ( nxtCol ) {
            nxtColWidth = nxtCol.offsetWidth;        
        }

    }    

    function handleMouseMove(e) {    

        if (curCol) {
            var diffX = e.pageX - pageX;
          
            if (nxtCol)
                nxtCol.style.width = (nxtColWidth - (diffX))+'px';
         
            curCol.style.width = (curColWidth + diffX)+'px';
        }        

    }

    function handleMouseUp(e) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
    }     

    const handleFilter = e => {
        setShowFilter( !showFilter );             
        setFilterParam({});        
        setFindParam({});
    }

    const handleSelectAndEdit = dataSelected => {

      props.history.push( `/app/${urn}/${dataSelected[keyname]}` );  

    }

    const handleDoubleClickRow = dataSelected => {
        handleSelectAndEdit( dataSelected );
    }

    const handleRowClick = dataSelected => {
        if ( props.onRowClick )
            props.onRowClick(dataSelected);
    }

    const handleKeyPress = e => {

        e.persist();    
        
        if ( e.charCode === 32 ) return true; // space
        
        var theEvent = e || window.event;
        var key = theEvent.keyCode || theEvent.which;
        
        key = String.fromCharCode( key );   
        var regex = /^[0-9:/]+$/;

        if ( !regex.test( key ) ) {

            theEvent.returnValue = false;

            if (theEvent.preventDefault) theEvent.preventDefault();
        }        

    }

    const handleClose = e => {
        e.preventDefault();
        props.history.goBack();
        //props.history.push('/app/home');
    }

    const handleNew = () => {
        //alert( 'Function not implemented...' );
        props.history.push( `/app/${urn}/new` ); 
    }

    const handleRefresh = async e => {

        e.preventDefault();
        e.persist();
        setData([]);
        setDataOriginal([]);
        loadData();

    }

    const handleClickColTitle = ( e, column ) => {

        let elements = document.getElementsByClassName('img-sort');
        let el = undefined;

        for ( let i = 0; i < elements.length; i++ ) {
            elements[i].src = '';
        }   

        el = document.getElementById( `imgSort${column.name}` );
        
        let newData = Object.assign([], data );

        if ( order === 0 ) {

            newData.sort( ( item1, item2 ) => { 

                var x = item1[ column.name ];
                var y = item2[ column.name ];
    
                return x < y ? -1 : x > y ? 1 : 0;
    
            });  

            setOrder( 1 );
            el.src = ImageSortArrowAsc16;

        } else {

            newData.sort( ( item1, item2 ) => { 

                var x = item2[ column.name ];
                var y = item1[ column.name ];
    
                return x < y ? -1 : x > y ? 1 : 0;
    
            });  

            setOrder( 0 );
            el.src = ImageSortArrowDes16;

        }

        setData( newData );        
     
    }

    const addColsTitle = () => {

        let colsTitle = [];

        function createColTitle( column ) {

            let colTitle = <td></td>;
            
            let id = 'col-grid-title-common';

            if ( column.idTitle.length > 0 )
                id = column.idTitle;            

            switch ( column.dataType ) {

                case 'string' : 
                    colTitle = <th id={id} onClick={(e) => handleClickColTitle(e,column)}>{column.title}<div id="columnSelector" onMouseDown={handleMouseDown}/> <img src={''} alt="" id={`imgSort${column.name}`} className="img-sort"/> </th>

                    fieldFilter.push (
                        <th id="col-grid-filter" key={`filter-${column.name}`}> 
                            <div>
                                <input 
                                    id="input-grid-filter" 
                                    name={column.name} 
                                    onChange={filter} 
                                    placeholder="Buscar"
                                />
                            </div>
                        </th>     
                    ); 

                break;
                
                case 'boolean' : 
                    colTitle = <th id="col-grid-title-checked" onClick={(e) => handleClickColTitle(e,column)}>{column.title}<div id="columnSelector" onMouseDown={handleMouseDown}/> <img src={''} alt="" id={`imgSort${column.name}`} className="img-sort"/>  </th>

                    fieldFilter.push (
                        <th id="col-grid-filter" key={`filter-${column.name}`}>
                            <div>
                                <input id="check-grid-filter" 
                                type="checkbox"
                                name={column.name} 
                                onChange={filter} 
                                placeholder="Buscar"/>
                            </div>
                        </th>     
                    );
                break;

                case 'number' : 
                    colTitle = <th id={id} onClick={(e) => handleClickColTitle(e,column)}>{column.title}<div id="columnSelector" onMouseDown={handleMouseDown}/> <img src={''} alt="" id={`imgSort${column.name}`} className="img-sort"/> </th>

                    fieldFilter.push (
                        <th id="col-grid-filter" key={`filter-${column.name}`}>
                            <div>
                                <input 
                                    id="input-grid-filter" 
                                    name={column.name} 
                                    onChange={filter} 
                                    placeholder="Buscar"
                                />
                            </div>
                        </th>     
                    ); 

                break;              

                case 'datetime' : 
                    colTitle = <td id="col-grid-title-date" onClick={(e) => handleClickColTitle(e,column)}> {column.title}<div id="columnSelector" onMouseDown={handleMouseDown}/> <img src={''} alt="" id={`imgSort${column.name}`} className="img-sort"/> </td>

                    fieldFilter.push (
                        <th id="col-grid-filter" key={`filter-${column.name}`}>
                            <div>
                                <input 
                                    id="input-grid-filter" 
                                    name={column.name} 
                                    onKeyPress={handleKeyPress} 
                                    onChange={props.onFilter} 
                                    placeholder="Buscar"
                                />
                            </div>
                        </th>     
                    );  

                break;

                default: 
                    colTitle = <td id="col-grid-title-common" onClick={(e) => handleClickColTitle(e,column)}>{column.title}<div id="columnSelector" onMouseDown={handleMouseDown}/> <img src={''} alt="" id={`imgSort${column.name}`} className="img-sort"/> </td>

                    fieldFilter.push (
                        <th id="col-grid-filter" key={`filter-${column.name}`}>
                            <div>
                                <input 
                                    id="input-grid-filter" 
                                    name={column.name} 
                                    onChange={filter} 
                                    placeholder="Buscar"
                                />
                            </div>
                        </th>     
                    ); 

                break;

            }    
            
            return colTitle;
            
        }

        columns.map ( column => (            

            colsTitle.push(  createColTitle( column ) )

        ));

        colsTitle.push(  <th id="col-grid-title-btn">Ações</th> );

        return colsTitle;

    }

    function filter(e) {

        let name = e.target.name;
        let value = undefined;
        
        switch ( e.target.name ) {
            case 'createdAt':
                value = DateUtils.formatJSONDate( e.target.value );
                break;
            case 'updatedAt':
                value = DateUtils.formatJSONDate( e.target.value );
                break;   
            default : 

                if ( e.target.type === 'checkbox' ) {
                    value = e.target.checked; 
                } else {    
                    value = e.target.value;
                }

                break;
        }

        // always delete the property before re-adding it with new value
        if ( value.length === 0 )
            delete filterParam[name];

        if ( typeof value === 'boolean' ) {
            setFilterParam(
                {...filterParam, [name]: value }
            );
        } else if ( value && value.toString().length > 0 ) {
            setFilterParam(
                {...filterParam, [name]: value }
            );
        } else {
            setData( dataOriginal );
        }

        /*

        if ( Object.keys( filterParam ).length > 0 ) {
            newListData = FilterElement( dataOriginal, filterParam );
        } else {
            newListData = dataOriginal;
        }

        // add new list off data filtred
        setData(newListData);

       // add filterParam in findParam to load data parametrized from data base
        setFindParam(filterParam); */

    }   


    const loadData = async () => {

        let response = undefined;

        delete findParam['createdAt'];
        delete findParam['updatedAt'];

        const paramProps = Object.getOwnPropertyNames(findParam);

        paramProps.forEach( paramProp => {

            if ( ( findParam[ paramProp ] === undefined ) || 
                 ( findParam[ paramProp ].length === 0 ) ) {

                delete findParam[ paramProp ];

            }

        });

        // busca parametrizada
        if ( Object.keys( findParam ).length > 0 ) {
            
            if ( uri )
                response = await api.post( `/${uri}/filter`, findParam );
            else    
                response = await api.post( `/${urn}/filter`, findParam );
            
        } else {

            if ( uri )
                response = await api.get( `/${uri}` );
            else    
                response = await api.get( `/${urn}/findAll` ); 
        }

        if ( response && response.data ) {

            if ( response && response.data ) {

                setDataOriginal( response.data );  
                setData( response.data );
                    
            } 

       }              
        
    }


    const addColsData = item => {

        function createColumn( column, data ) {

            let colData = <td></td>;
            let id = 'col-grid-data-common';

            if ( column.idData.length > 0 )
                id = column.idData;

            switch ( column.dataType ) {

                case 'string' : 
                    colData = <td id={id}>{data[column.name]}</td>
                break;

                case 'number' : 
                    colData = <td id={id}>{data[column.name]}</td>
                break;                
                
                case 'boolean' : 
                    colData = <td id="col-grid-data-checked"><input type="checkbox" disabled={true} checked={data[column.name]} ></input></td>
                break;

                case 'datetime' : 
                    colData = <td id="col-grid-data-date"> {data[column.name]}</td>
                break;

                default: 
                    colData = <td id="col-grid-data-common">{data[column.name]}</td>
                break;

            }

            return colData;

        }

        let colsData = [];

        columns.map ( column => (

            colsData.push( createColumn( column, item ) )
    
        ));  

        return colsData;

    }

    var idRow = 0;
    const genIdRow = () =>{
        idRow = idRow + 1;
        return idRow;
    }
    
    return ( 
        
        <div className="container" > 
            <AHeader title={title} />
            <ButtonBar 
                buttonBarType={'Browser'} 
                onClose={handleClose}
                onNew={handleNew}
                onRefresh={handleRefresh} 
                onFind={handleFilter} 
            />
            <div id="base-grid" >
                <table id="common-grid" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}  >
                    <thead>
        
                        <tr id="row-grid-title">
                            { addColsTitle() }
                        </tr>
                        {   
                            showFilter &&                             
                            <tr>{fieldFilter}</tr>                                                    
                        }

                    </thead>
                    <tbody className="body-grid">

                        {
                            data.map( item => ( 

                                <tr key={ genIdRow() } 
                                    id="row-grid-data" 
                                    onDoubleClick={ () => handleDoubleClickRow( item ) }  
                                    onClick={ () => handleRowClick( item ) }
                                    >

                                    { addColsData( item ) }    

                                    <td id="col-grid-btn">
                                        <div className="grid-buttons">
                                            <button onClick={ () => handleSelectAndEdit( item ) }>
                                                <img src={ImageEdit24} alt="" />
                                            </button>
                                        </div>
                                    </td>

                                </tr>
                        
                            ))
                        }
                    
                    </tbody>
                </table>  
            </div>      
        </div>
        
    )

}

export default withRouter( ABrowser );