import React from 'react';

import ABrowser from '../../../components/ABrowser';

function CompanyBrowser( props ) {

    let columns = [];

    const createSpecColumn = ( name, title, dataType, idData = '', idTitle = '' ) => {
        return { name: name, title: title, dataType: dataType, idData: idData, idTitle: idTitle };
    }

    columns.push( createSpecColumn( 'as_empCnpj', 'CNPJ', 'string', 'col-grid-data-cnpj', 'col-grid-title-cnpj' ) );
    columns.push( createSpecColumn( 'as_empRazao', 'Razão Social', 'string' ) );
    columns.push( createSpecColumn( 'as_gpeDescricao', 'Grupo', 'string' ) );
    columns.push( createSpecColumn( 'as_empAtivo', 'Ativo', 'boolean' ) );

    return (

        <ABrowser 
            urn='empresas' 
            title='Empresas' 
            keyname='as_empId' 
            columns={columns}
        />
        
    );

}

export default CompanyBrowser;



























  /*
    return ( 
        
        <>
            <Header title={'Empresas'} />
            <ButtonBar 
                buttonBarType={'Browser'} 
                onClose={handleClose}
                onNew={handleNew}
                onRefresh={handleRefresh} 
                onFind={handleFind} 
            />
            <div id="base">
                <table id="common-grid" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}  >
                    <thead>
                        <tr id="row-grid-title">
                            <th id="col-grid-title-cnpj">CNPJ<div id="columnSelector" onMouseDown={handleMouseDown}/></th>
                            <th id="col-grid-title-common">Razão Social<div id="columnSelector" onMouseDown={handleMouseDown}/></th>
                            <th id="col-grid-title-checked">Ativo<div id="columnSelector" onMouseDown={handleMouseDown}/></th>
                            <th id="col-grid-title-btn">Ações</th>
                        </tr>
                    </thead>
                    <tbody id="body-grid">

                    { data.map( item => (
                        <tr id="row-grid-data" key={ item.as_empId} onDoubleClick={ () => handleSelectAndEdit( item ) }  >
                        
                            <td id="col-grid-data-cnpj">{item.as_empCnpj}</td>
                            <td id="col-grid-data-common">{item.as_empRazao}</td>
                            <td id="col-grid-data-checked"><input type="checkbox" disabled={true} checked={item.as_empAtivo === 1} ></input></td>
                            <td id="col-grid-btn">
                                <div id="grid-buttons">
                                    <button onClick={ () => handleSelectAndEdit( item ) }>
                                        <img src={ImageEdit24} alt="" />
                                    </button>
                                </div>
                            </td>

                        </tr> ))
                        
                    }
                    
                    </tbody>
                </table>  
            </div>      
        </>
        
    );*/