import axios from  'axios';
import { environment } from '../settings/environment.prod';

export const api = axios.create({
    baseURL: environment.API_V1,
    headers: {  
                'Content-Type': 'application/json; charset=utf-8',                    
                'Accept': '*/*' 
    }
});

