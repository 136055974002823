import React from 'react';

import './styles.scss';
import { ImageAdd24, ImageLoad24, ImageSearch24, ImageBack24, ImageSave24 } from '../../image/image24';

function ButtonBar( props ) {

    const makeButtonEdit = () => {

        const { onClose, onSave } = props;

        const buttons = <div className="buttons">                
                            <button onClick={onClose}>
                                <img src={ImageBack24} alt="" title='Sair'/> 
                                <div className="button-divider"/>
                            </button>
                            <button onClick={onSave}>
                                <img src={ImageSave24} alt="" title='Salvar'/> 
                            </button>
                        </div>

        return buttons;

    }

    const makeButtonBrowser = () => {

        const { onClose, onNew, onRefresh, onFind } = props; // desestruturing


        const buttons = <div className="buttons">                
                            { onClose &&
                            <button onClick={onClose}>
                                <img src={ImageBack24} alt="" title='Sair'/>                                
                                <div className="button-divider"/>
                            </button>
                            }
                            { onNew &&
                            <button onClick={onNew}>
                                <img src={ImageAdd24} alt="" title="Novo" />
                            </button>
                            }
                            { onRefresh &&
                                <button onClick={onRefresh} >
                                    <img src={ImageLoad24} alt="" title="Carregar/Atualizar" />
                                </button>
                            }
                            { onFind &&
                            <button onClick={onFind}>
                                <img src={ImageSearch24} alt="" title="Procurar" />
                            </button>
                            }
                        </div>
                        
        return buttons;

    }

    const factoryButtons = ( ) => {

        if ( props.buttonBarType === 'Edit' ) {

            return makeButtonEdit();

        } else if ( props.buttonBarType === 'Browser' ) {

            return makeButtonBrowser();
        }
        
    }

    return (

        <div className="button-bar" >
            <div className="buttons">                
                { factoryButtons() }
            </div>
        </div>
            
        
    )

}

export default ButtonBar;