import React from 'react';

import './styles.scss';

export default function AInput( props ) {

    const { name, type, caption, minLength, maxLength, required, data, onChange, onBlur, onInput } = props;
    let value = data[name];

    if ( value === undefined && type === 'text' ) {
        value = '';
    }

    const handleBlur = e => {

        if ( onBlur ) {
            onBlur(e);
        }

        if ( e.target.required ) {
 
            if ( e.target.value === undefined || e.target.value.length < 1 )
               e.target.placeholder = '* Preechimento obrigatório'
            else
              e.target.placeholder = undefined;
        }

    }

    return (
    
        <div className='input-content' >            
            <label for={name}>{caption}</label>             
            <input 
                type={type} 
                id={name} 
                name={name}
                minLength={minLength} 
                maxLength={maxLength} 
                required={required}
                onChange={onChange}
                onInput={onInput}     
                onBlur={handleBlur}         
                value={value}
            />           
        </div>
        
    );

}


//background-repeat: no-repeat;
//background-image:url('../../image/24/Search.png');