import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompanyBrowser from '../pages/company/browser/index';
import CompanyEdit from '../pages/company/edit';

export default function CompanyRouter() {

    return (

        <Switch>
            <Route exact path="/app/empresas" component={CompanyBrowser} />
            <Route path="/app/empresas/new" component={CompanyEdit} />
            <Route path="/app/empresas/:id" component={CompanyEdit} />            
        </Switch>
        
    )

}