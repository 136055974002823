import React, { useState, useEffect } from 'react';
import AHeader from '../../../components/Header';
import ButtonBar from '../../../components/ButtonBar';

import AInput from '../../../components/AInput';
import ADate from '../../../components/ADate';
import ACheckBox from '../../../components/ACheckBox';

import imgSearch from '../../../image/24/Search.png';


import './styles.scss';

import { api } from '../../../service/api';
import ASelect from '../../../components/ASelect';
import { FormatCNPJ, FormatCEP, FormatPhone } from '../../../utils/formatter';
import { StatesBrazil } from '../../../utils/statesBrazil';
import { ValidateForm, ValidateCNPJ } from '../../../utils/FormUtils';
import { environment } from '../../../settings/environment.prod';
import ADialog from '../../../components/ADialog';


function CompanyEdit( props ) {

    const urn = 'empresas';

    const { id } = props.match.params;
    const [ foundCNPJWS, setFoundCNPJWS ] = useState(false);

    const [data, setData] = useState({});    
    useEffect( () => {

        async function fetchData() {

            const response = await api.get( `/${urn}/findId/${id}` );   
            
            if ( response.data && Object.keys( response.data ).length > 0  ) {

                setData( response.data ); 

            } 
           
        }
         
        fetchData();
        
        return () => {
            setData({});
        }

    }, [id] );  


    const [companyGroups, setCompanyGroups] = useState([]);
    useEffect( () => {

        async function fetchData() {

            const response = await api.get( 'grupoEmpresas/findAll' ); 

            if ( response.data && Object.keys( response.data ).length > 0  ) {

                setCompanyGroups( response.data );

            }

        }

        fetchData();
        
        return () => {

            setCompanyGroups([]);

        }

    }, []);
    
    const [ showDialog, setShowDialog ] = useState({ show: false, message: ''});

    const updateState = e =>  {

        var value = undefined;

        if ( e.target.type === 'checkbox'  ) {

            if ( e.target.checked )
                value = 1
            else   
                value = 0;

        } else {
            value = e.target.value;
        }   

        setData({
            ...data, [e.target.name]: value 
        }); 

    }


    const save = async () => {

        if ( data.as_empId === undefined || data.as_empId === 0 ) {

            await api.post( urn, data );

        } else {

            await api.put( `${urn}/${data.as_empId}`, data );

        }

    }         

    const handleSave = async ( e ) => {

        e.persist();
        e.preventDefault();
       
        if ( validateForm( e.target ) === true ) {
            await save();      
            props.history.goBack();
        }   
        
    } 
                
    const validateForm = form => {  

        if (data.as_empCnpj) {        
            if ( !ValidateCNPJ( data.as_empCnpj ) ) {

                setShowDialog({ 
                    ...showDialog, 
                        show    : true,
                        message : 'CNPJ Inválido.',
                }); 

                return false;

            }
        }

        const validate = ValidateForm( form );        
        let ele = undefined;

        if ( !validate.frmValid ) { 
            
            ele = validate.element;

            setShowDialog({ 
                ...showDialog, 
                    show    : true,
                    message : validate.message,
            }); 

        }

        if ( ele )
            document.getElementById(ele.id).focus();

        return validate.frmValid;   
        
    }

    
    const handleSearchCNPJ = async e  => {
      
        e.persist();
        e.preventDefault();

        if ( !data.as_empCnpj || data.as_empCnpj.length < 1 ) return;

        findCompanyByCNPJ( data.as_empCnpj );

    }

    const handleCLose = e => {
        e.preventDefault();
        props.history.goBack();
    }

    const handleInputCNPJ = e => {
        e.target.value = FormatCNPJ( e.target.value );
    }

    const handleBlurCNPJ = async e => {

        if ( !foundCNPJWS ) {

            let cnpj = e.target.value;

            if ( cnpj && cnpj.length > 0 )
                cnpj = cnpj.replace(/\D/g,"")

            if ( id === undefined ) { // se for novo inserção verifica se o cnpj já existe e carrega os dados

                const response = await api.get( `/empresas/find/${cnpj}` ); 

                if ( response.data !== null )
                    setData( response.data )
                else    
                    findCompanyByCNPJ( cnpj );

            }

        }

    }

    const handleInputCEP = e => {
        e.target.value = FormatCEP( e.target.value );
    }
    const handleInputFone = e => {
        e.target.value = FormatPhone( e.target.value );
    }

    const handleCloseDialog = () => {

        setShowDialog({ 
            ...showDialog, 
                show    : false,
                message : ''
        });        

    }

    const findCompanyByCNPJ = async cnpj => {
        
        cnpj = cnpj.replace(/\D/g,"");

        let response = undefined;

        try {

            response = await api.get( `${environment.API_V1}/ws/empresa/${cnpj}` ); 

            if ( response.data.status === "ERROR" ) { // Comparação com dois iguais pois aqui pode vir número.
                throw new Error( response.data.message );
            }
            
            const company = response.data;
            
            if ( company && Object.keys( company ).length > 0  ) {
    
                //data.as_empId
                //data.as_empGpeId
    
                let cep = '';
    
                if ( company.cep )
                    cep = company.cep.replace(/\D/g,"");
    
                setData({
                    ...data, as_empRazao     : company.nome,
                             as_empEndereco  : `${company.logradouro}, ${company.numero}`,
                             as_empCep       : FormatCEP( cep ),
                             as_empBairro    : company.bairro,
                             as_empCidade    : company.municipio,
                             as_empUf        : company.uf,
                             as_empCompl     : company.complemento,
                             as_empFonePrinc : FormatPhone( company.telefone )
                });

                setFoundCNPJWS( true );
    
            }            
        
        } catch ( err ) {

            let msg = err.message;

            if ( msg.includes( 'code 429') ) {
                msg = 'Excedeu o limite de 3 consultas por minuto no serviço da Receita Federal. Aguarde um minuto e consulte novamente.';
            }

            setShowDialog({ 
                ...showDialog, 
                    show    : true,
                    message : msg,
            }); 

        }

    }

    return (

        <>
            <form  onSubmit={handleSave} noValidate>
            <AHeader title={'Editar Empresa'} />
                <ButtonBar 
                    buttonBarType={'Edit'} 
                    onClose={handleCLose}
                />                

                    <header className="header-title" >
                        <h3>Grupo</h3>                       
                        <hr/>  
                    </header> 
                    
                    <table className="grid-window" >
                            <tr className="row-window">
                                <td className='col-window' >
                                   
                                    <ASelect
                                        name={'as_empGpeId'} 
                                        keyname={'as_gpeId'}
                                        desc={'as_gpeDescricao'}                                                                                 
                                        caption={'Grupo'} 
                                        data={data} 
                                        options={companyGroups}
                                        onChange={updateState}
                                        required={true}
                                    />
                                   
                                </td> 
                            </tr>
                    </table>
               
                    <header className="header-title" >
                        <h3>Empresa</h3> 
                        <hr/>                       
                    </header> 
                  
                        <table className="grid-window" >
                            <tr className="row-window">
                                <td className='col-window-cnpj'>
                                    <div className="input-image" >
                                        <div className="content-input-image">  
                                            <div>                                          
                                                <AInput 
                                                    name={'as_empCnpj'} 
                                                    type={'text'} 
                                                    caption={'CNPJ'} 
                                                    data={data} 
                                                    onChange={updateState}
                                                    onInput={handleInputCNPJ}
                                                    onBlur={(e) => handleBlurCNPJ(e)}
                                                    minLength={18}
                                                    maxLength={18}
                                                    required={true}
                                                />  
                                            </div>                                          
                                            <button onClick={(e)=>handleSearchCNPJ(e)} >
                                                <img src={imgSearch} alt="" height="24px" width="24px"/>         
                                            </button>
                                        </div>
                                     </div>
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empRazao'} 
                                        type={'text'} 
                                        caption={'Razão Social'} 
                                        data={data} 
                                        onChange={updateState}
                                        minLength={2}
                                        maxLength={80}
                                        required={true}
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empInsc_Est'} 
                                        type={'text'} 
                                        caption={'Inscrição Estadual'} 
                                        data={data} onChange={updateState}
                                        maxLength={18}                                        
                                    />
                                </td>
                            </tr>
                            <tr  className="row-window">
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empEndereco'} 
                                        type={'text'} 
                                        caption={'Endereço'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={120}
                                        required={true}
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empCep'} 
                                        type={'text'} caption={'CEP'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputCEP}
                                        minLength={9}
                                        maxLength={9}
                                        required={true}
                                    />
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empBairro'} 
                                        type={'text'} 
                                        caption={'Bairro'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={40}
                                        required={true}
                                    />
                                </td>   
                            </tr>  
                            <tr  className="row-window">
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empCidade'} 
                                        type={'text'} 
                                        caption={'Cidade'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={40}
                                        minLength={2}
                                        required={true}
                                    />
                                </td>
                                <td className='col-window' >
                                    <ASelect
                                        name={'as_empUf'} 
                                        keyname={'as_empUf'}
                                        desc={'sigla'}                                                                                 
                                        caption={'UF'} 
                                        data={data} 
                                        options={StatesBrazil.UF}
                                        onChange={updateState}
                                        required={true}
                                        maxLength={2}
                                    />
                                                                       
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empCompl'} 
                                        type={'text'} 
                                        caption={'Complemento'} 
                                        data={data} 
                                        onChange={updateState}
                                        maxLength={200}
                                    />
                                </td>
                            </tr>  
                            <tr  className="row-window">
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empFonePrinc'} 
                                        type={'text'} 
                                        caption={'Telefone Principal'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputFone}
                                        minLength={13}
                                        maxLength={14}
                                        required={true}
                                    />
                                </td>                        
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empFone2'} 
                                        type={'text'} 
                                        caption={'Telefone'} 
                                        data={data} 
                                        onChange={updateState}
                                        onInput={handleInputFone}
                                        minLength={14}
                                        maxLength={14}
                                    />
                                </td>
                            </tr>  
                            <tr className="row-window">
                                <td className='col-window' >
                                    <ADate 
                                        name={'as_empValidade'}                                         
                                        caption={'Validade'} 
                                        data={data} 
                                        onChange={updateState}
                                        required={true}
                                    />
                                </td>
                                <td className='col-window' >
                                    <AInput 
                                        name={'as_empQtdLicencas'} 
                                        type={'number'} 
                                        caption={'Qtd. Licenças'} 
                                        data={data} 
                                        onChange={updateState}
                                        required={true}
                                    />
                                </td>     
                                <td className='col-window' >
                                    <ACheckBox 
                                        name={'as_empAtivo'} 
                                        caption={'Ativo'} 
                                        data={data} 
                                        onChange={updateState}
                                    />
                                </td>                        
                            </tr>
                        </table>    
                </form>
                { 
                    showDialog.show &&
                    <ADialog 
                        message={showDialog.message} 
                        onClose={handleCloseDialog}
                    />
                }
        </>
        
    );//*/

}

export default CompanyEdit;


/*

.grid-container {
  display: grid;
  height: 400px;
  align-content: end;
}

<IonContent >
<IonItemGroup>
    <IonItemDivider sticky ="true">
        <IonLabel position="fixed" id="common-label-divider" >Modalidade</IonLabel>
    </IonItemDivider>   
    <div id="base-items">
        <IonItem>    
            <IonLabel position="fixed">Grupo</IonLabel>
            <IonInput 
                name="group" 
                type="text" 
                required="true" 
                minlength="2" 
                placeholder="Digite o grupo da modalidade" 
                value={modality.group} 
                onIonInput={onChange} 
                onIonBlur={this.onBlur}
                onIonFocus={this.onFocus}                                
            />
        </IonItem>
        <IonItem>
            <IonLabel position="fixed" >Nome</IonLabel>
            <IonInput 
                name="name" 
                type="text"
                required="true" 
                minlength="2" 
                placeholder="Digite o nome da modalidade" 
                value={modality.name} 
                onIonInput={onChange} 
                onIonFocus={this.onFocus} 
                onIonBlur={this.onBlur}
            />
        </IonItem>
        <IonItem>
            <IonLabel position="fixed" >Ativo</IonLabel>
            <IonToggle 
                name="isActive" 
                color="primary" 
                checked={modality.isActive} 
                onIonChange={onChange}>
            </IonToggle>
        </IonItem>
        <IonItem>
            <IonLabel position="fixed" >Observação</IonLabel>
            <IonTextarea 
                name="note" 
                value={modality.note} 
                placeholder="Digite a observação" 
                onIonInput={onChange} 
                onIonFocus={this.onFocus} 
                onIonBlur={this.onBlur}
            />
        </IonItem>
    </div>
</IonItemGroup>
</IonContent> */
/*



<div className='grid-window'>
                        <tr className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empCnpj'} type={'text'} caption={'CNPJ'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empRazao'} type={'text'} caption={'Razão Social'}></AInput>                                
                            </td>                        
                        </tr>
                        <tr  className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empInsc_Est'} type={'text'} caption={'Inscrição Estadual'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empEndereco'} type={'text'} caption={'Endereço'}></AInput>
                            </td>                        
                        </tr>  
                        <tr  className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empCep'} type={'text'} caption={'CEP'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empBairro'} type={'text'} caption={'Bairro'}></AInput>
                            </td>                        
                        </tr>  
                        <tr className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empCidade'} type={'text'} caption={'Cidade'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empUF'} type={'text'} caption={'UF'}></AInput>
                            </td>                        
                        </tr>  
                        <tr className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empCompl'} type={'text'} caption={'Complemento'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empFonePrinc'} type={'text'} caption={'Telefone Principal'}></AInput>
                            </td>                        
                        </tr> 
                        <tr className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empFone2'} type={'text'} caption={'Telefone'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empAtivo'} type={'text'} caption={'Ativo'}></AInput>
                            </td>                        
                        </tr>  
                        <tr className="row-window">
                            <td className='col-window' >
                                <AInput name={'as_empValidade'} type={'text'} caption={'Validade'}></AInput>
                            </td>
                            <td className='col-window' >
                                <AInput name={'as_empQtdLicencas'} type={'text'} caption={'Qtd. Licenças'}></AInput>
                            </td>                        
                        </tr>                                                                       
                    </div>    


                    */
