export const TOKEN_KEY = '@america_api';
export var IS_LOGGED = false;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
    localStorage.setItem( TOKEN_KEY, token );
    IS_LOGGED = true;
};

export const logout = () => {
    localStorage.removeItem( TOKEN_KEY );
    IS_LOGGED = false;
};