import React, { useState, useEffect } from 'react';
import AHeader from '../../../components/Header';
import ButtonBar from '../../../components/ButtonBar';

import AInput from '../../../components/AInput';

import './styles.scss';

import { api } from '../../../service/api';
import { ValidateForm } from '../../../utils/FormUtils';
import ADialog from '../../../components/ADialog';


function CompanyGroupEdit( props ) {

    const urn = 'grupoEmpresas';

    const { id } = props.match.params;
    const [data, setData] = useState({});    
    useEffect( () => {

        async function fetchData() {

            const response = await api.get( `/${urn}/findId/${id}` ); 
            
            if ( response.data && Object.keys( response.data ).length > 0  ) {

                setData( response.data ); 

            } 
           
        }
         
        fetchData();
        
        return () => {
            setData({});
        }

    }, [id] );  

    const [ showDialog, setShowDialog ] = useState({ show: false, message: ''});


    const updateState = e =>  {

        var value = undefined;

        if ( e.target.type === 'checkbox'  ) {

            if ( e.target.checked )
                value = 1
            else   
                value = 0;

        } else {
            value = e.target.value;
        }   

        setData({
            ...data, [e.target.name]: value 
        }); 

    }


    const save = async () => {

        if ( data.as_gpeId === undefined || data.as_gpeId === 0 ) {

            await api.post( urn, data );

        } else {


            await api.put( `${urn}/${data.as_gpeId}`, data );

        }

    }         

    const handleSave = async ( e ) => {

        e.persist();
        e.preventDefault();
       
        if ( validateForm( e.target ) === true ) {
            await save();      
            props.history.goBack();
        }   
        
    } 

    const validateForm = form => {

        const validate = ValidateForm( form );        
        let ele = undefined;

        if ( !validate.frmValid ) { 
            
            ele = validate.element;

            setShowDialog({ 
                ...showDialog, 
                    show    : true,
                    message : validate.message,
            }); 

        }

        if ( ele )
            document.getElementById(ele.id).focus();

        return validate.frmValid; 
        
    }

    const handleCLose = e => {
        e.preventDefault();
        props.history.goBack();
    }

    const handleCloseDialog = () => {

        setShowDialog({ 
            ...showDialog, 
                show    : false,
                message : ''
        });        

    }



    return (

        <div class="container">
            <form  onSubmit={handleSave} noValidate>
            <AHeader title={'Editar Grupo de Empresa'} />
                <ButtonBar 
                    buttonBarType={'Edit'} 
                    onClose={handleCLose}
                />                
                    
                <header className="header-title" >
                    <h3>Grupo de Empresa</h3> 
                    <hr/>                       
                </header>               
                <div>
                    <AInput 
                        name={'as_gpeDescricao'} 
                        type={'text'} 
                        caption={'Descrição'} 
                        data={data} 
                        onChange={updateState} 
                        required={true}
                        minLength={2}
                        maxLength={60}
                    />
                </div>      
                { /*                
                <table className="grid-window" >
                    <tr className="row-window">
                        <td className='col-window'>
                            <AInput 
                                name={'as_gpeDescricao'} 
                                type={'text'} 
                                caption={'Descrição'} 
                                data={data} 
                                onChange={updateState} 
                                required={true}
                                minLength={2}
                                maxLength={60}
                            />
                           
                        </td>
                        <td className='col-window' >
                            <ADate 
                                name={'as_gpeValidade'}                                         
                                caption={'Validade'} 
                                data={data} 
                                onChange={updateState}
                                required={true}
                            />
                        </td>
                    </tr>  
                    <tr className="row-window">
                        <td className='col-window' >
                            <AInput 
                                name={'as_gpeQtdLicencas'} 
                                type={'number'} 
                                caption={'Qtd. Licenças'} 
                                data={data} 
                                onChange={updateState}
                                required={true}
                            />
                        </td>  
                        <td className='col-window' >
                            <ACheckBox 
                                name={'as_gpeAtivo'} 
                                caption={'Ativo'} 
                                data={data} 
                                onChange={updateState}
                            />
                        </td>                        

                    </tr>
                </table>                              
                */}
            </form>
            { 
                showDialog.show &&
                <ADialog 
                    message={showDialog.message} 
                    onClose={handleCloseDialog}
                />
            }

        </div>
        
    );//*/

}

export default CompanyGroupEdit;