import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import './styles.scss';
import { logout, IS_LOGGED } from '../../service/auth';

function AMainHeader(props) {

    const [menuHidden, setMenuHidden] = useState(false);
    useEffect(() => {

        setMenuHidden(!IS_LOGGED);

    }, [IS_LOGGED]);


    const handleClick = (e, urn) => {

        e.persist();
        e.preventDefault();

        let options = document.getElementsByClassName('menu-option');
        let opt = undefined;

        for (let i = 0; i < options.length; i++) {
            options[i].style = 'none';
        }

        //e.target.style.fontWeight = '600';
        //e.target.style.color = '#ffd700';
        //e.target.style.color = '#ced9f8';
        e.target.style.color = '#e5e619';
        e.target.style.textDecorationLine = "underline";

        if (urn === '')
            alert('Resource not implemented...')
        else
            props.history.push(`/app/${urn}`);

    }

    const handleClickLogoff = e => {

        logout();
        setMenuHidden(true);
        localStorage.setItem('username', '');

        props.history.push('/crm');


    }

    return (

        <header className="aheader">
            <a href="home" className="logo">América Soft</a>
            <div className="aheader-right" hidden={menuHidden} >

                <p className="menu-option" onClick={(e) => handleClick(e, 'grupoempresas')}>Grupos de Empresa</p>
                <p className="menu-option" onClick={(e) => handleClick(e, 'empresas')}>Empresas</p>
                <p className="menu-option" onClick={(e) => handleClick(e, 'filiais')}>Filiais</p>
                <p className="menu-option" onClick={(e) => handleClick(e, 'audit')}>Auditoria</p>
                <p className="active" onClick={(e) => handleClickLogoff(e)}>Sair</p>

            </div>
        </header>

    )



}

export default withRouter(AMainHeader);

