import React, { useState, useEffect } from 'react';

import { api } from '../../service/api';
import AHeader from '../../components/Header';
import ABrowser from './browser';
import { ImageSortArrowAsc16, ImageSortArrowDes16 } from '../../image/image16';

import './styles.scss';

const urn = 'logsis';

export default function Audit(props) {

    const [detailDesc, setDetailDesc] = useState('');
    const [order, setOrder] = useState(0); // 0 = crescente, 1 = decrescente

    // visão macro

    let columnsAuditEmp = [];

    const createSpecCol = (name, title, dataType, idData = '', idTitle = '') => {
        return { name: name, title: title, dataType: dataType, idData: idData, idTitle: idTitle };
    }

    columnsAuditEmp.push( createSpecCol( 'as_empRazao', 'Razão Social', 'string', 'col-grid-data', 'col-grid-title-common' ));
    columnsAuditEmp.push( createSpecCol( 'as_mesAnoAcesso', 'Período', 'string' ));
    columnsAuditEmp.push( createSpecCol( 'as_empAtivo', 'Cliente Ativo', 'boolean' ));
    columnsAuditEmp.push( createSpecCol( 'qt_usuario', 'Usuários', 'number' ));
    columnsAuditEmp.push( createSpecCol( 'qt_maquina', 'Estações', 'number' ));
    columnsAuditEmp.push( createSpecCol( 'as_empQtdlicencas', 'Licenças', 'number' ));
    columnsAuditEmp.push( createSpecCol( 'as_usoInferior', 'Inferior', 'boolean' ));
    columnsAuditEmp.push( createSpecCol( 'as_usoExcedente', 'Excedente', 'boolean' ));
    columnsAuditEmp.push( createSpecCol( 'as_qtDivergente', 'Qtd. Divergente', 'number' ));

    // visão detalhada

    let columnsAuditEmpDetail = [];


    columnsAuditEmpDetail.push(createSpecCol('as_usuarioSIS', 'Usuário', 'string', 'col-grid-data', 'col-grid-title-common'));
    columnsAuditEmpDetail.push(createSpecCol('as_ipMaquina', 'IP Estação', 'string'));
    columnsAuditEmpDetail.push(createSpecCol('as_filRazao', 'Razão Social - Filial', 'string'));

    const [dataDetail, setDataDetail] = useState([]);
    const [paramDetail, setParamDetail] = useState({ as_empId: 0, periodo: '' });
    useEffect(() => {

        async function fetchData() {

            if (paramDetail.as_empId && paramDetail.as_empId > 0) {

                const response = await api.get(`/${urn}/auditempdetail/${paramDetail.as_empId}/${paramDetail.periodo}`);
                setDataDetail(response.data);
                setDetailDesc( ` - ${response.data[0].as_filRazao}`  );

            }

        }



        fetchData();

        return () => {
            setDataDetail([]);
        }

    }, [paramDetail]);


    const handleRowClick = dataSelected => {
        setParamDetail({
            as_empId: dataSelected.as_empId,
            periodo: dataSelected.as_mesAnoAcesso
        });
    }

    let idRow = 0;

    const getIdRow = () => {
        idRow = idRow + 1
        return idRow;
    }

    const handleClickColTitle = ( e, columnName ) => {

        let elements = document.getElementsByClassName('img-sort');
        let el = undefined;

        for ( let i = 0; i < elements.length; i++ ) {            
            elements[i].src = '';
        }   

        el = document.getElementById( `imgSort${columnName}` );
        
        let newData = Object.assign([], dataDetail );

        if ( order === 0 ) {

            newData.sort( ( item1, item2 ) => { 

                var x = item1[ columnName ];
                var y = item2[ columnName ];
    
                return x < y ? -1 : x > y ? 1 : 0;
    
            });  

            setOrder( 1 );
            el.src = ImageSortArrowAsc16;

        } else {

            newData.sort( ( item1, item2 ) => { 

                var x = item2[ columnName ];
                var y = item1[ columnName ];
    
                return x < y ? -1 : x > y ? 1 : 0;
    
            });  

            setOrder( 0 );
            el.src = ImageSortArrowDes16;

        }

        setDataDetail( newData );        
     
    }    

    return (

        <div className="content-audit">
            <div className="base-browser-audit">
                <ABrowser
                    urn={'empresas'}
                    uri={'logsis/auditemp'}
                    title='Auditoria'
                    keyname={'as_empId'}
                    columns={columnsAuditEmp}
                    onRowClick={(e) => handleRowClick(e)}
                />    
            </div>    
            <div className="base-detail-audit">                   
                    <AHeader title={`Detalhes de acesso ${detailDesc}`} />
                    <table id="common-grid-audit" >                    
                        <thead>                            
                            <tr id="row-grid-title-detail-audit" >
                                <td 
                                    id="col-grid-title-common-detail-audit"                                     
                                    className="columnSort" 
                                    onClick={(e)=>handleClickColTitle(e, 'as_usuarioSIS')}>
                                    Usuário 
                                    <img 
                                        src={''} 
                                        alt="" 
                                        id={`imgSort${'as_usuarioSIS'}`} 
                                        className="img-sort"
                                    />
                                </td>                            
                                <td 
                                    id="col-grid-title-common-detail-audit"                                     
                                    className="columnSort" 
                                    onClick={(e)=>handleClickColTitle(e, 'as_ipMaquina')}>
                                    IP Estação
                                    <img 
                                        src={''} 
                                        alt="" 
                                        id={`imgSort${'as_ipMaquina'}`} 
                                        className="img-sort"
                                    />
                                </td>
                                <td 
                                    id="col-grid-title-common-detail-audit"                                     
                                    className="columnSort" 
                                    onClick={(e)=>handleClickColTitle(e, 'as_filRazao')}>
                                    Filial
                                    <img 
                                        src={''} 
                                        alt="" 
                                        id={`imgSort${'as_filRazao'}`} 
                                        className="img-sort"
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                dataDetail.map( item => ( 

                                    <tr key={ getIdRow() } 
                                        id="row-grid-data-audit" 
                                    >
                                        <td id="col-grid-data-common-audit">{item.as_usuarioSIS}</td>
                                        <td id="col-grid-data-common-audit">{item.as_ipMaquina}</td>
                                        <td id="col-grid-data-common-audit">{item.as_filRazao}</td>
                                    </tr>
                            
                            
                                ))
                            }
                        
                        </tbody>
                    </table>  
                </div>                       
        </div>

        /*


                                                 

       
        <>
            <div className='content-audit'>
                <div className="base-browser-audit">
                    <ABrowser
                        urn={'empresas'}
                        uri={'logsis/auditemp'}
                        title='Auditoria' 
                        keyname={'as_empId'}
                        columns={columnsAuditEmp}
                        onRowClick={(e)=>handleRowClick(e)}
                    />
                </div>
                <div className="base-detail">
                    <AHeader title={'Detalhamento'} />
                    <table id="common-grid" >                    
                        <thead>
                            <tr id="row-grid-title" >
                                <td id="col-grid-title-common">Usuário</td>                            
                                <td id="col-grid-title-common">IP Estação</td>
                                <td id="col-grid-title-common">Filial</td>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                dataDetail.map( item => ( 

                                    <tr key={ getIdRow() } 
                                        id="row-grid-data" 
                                    >
                                        <td id="col-grid-data-common">{item.as_usuarioSIS}</td>
                                        <td id="col-grid-data-common">{item.as_ipMaquina}</td>
                                        <td id="col-grid-data-common">{item.as_filRazao}</td>
                                    </tr>
                            
                            
                                ))
                            }
                        
                        </tbody>
                    </table>  
                </div>    
            </div>

        </>
        //*/
    )

}