import React from 'react'

import './styles.scss';

export default function ACheckBox( props ) {

    const { name, caption, data, onChange } = props;

    let value = data[name];

    if ( typeof value === 'number' )
        value = value === 1;

    return (
        <div className='ckeckbox-content' >            
            <input 
                type="checkbox" 
                id={name}  
                name={name}               
                checked={value} 
                onChange={onChange}
            />  
            <label for={name}>{caption}</label>
        </div>
    );

}