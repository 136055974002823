import React from 'react';

import ABrowser from '../../../components/ABrowser';

function BranchOfficeBrowser( props ) {

    let columns = [];

    const createSpecColumn = ( name, title, dataType, idData = '', idTitle = '' ) => {
        return { name: name, title: title, dataType: dataType, idData: idData, idTitle: idTitle };
    }

    columns.push( createSpecColumn( 'as_filCnpj', 'CNPJ', 'string', 'col-grid-data-cnpj', 'col-grid-title-cnpj' ) );
    columns.push( createSpecColumn( 'as_filRazao', 'Razão Social', 'string' ) );
    columns.push( createSpecColumn( 'as_empRazao', 'Empresa', 'string' ) );
    columns.push( createSpecColumn( 'as_filAtivo', 'Ativo', 'boolean' ) );

    return ( 

            <ABrowser
                urn='filiais' 
                title='Filiais' 
                keyname='as_filId' 
                columns={columns}
            />
    )

}

export default BranchOfficeBrowser;