import React from 'react';

import './styles.scss';
import { ImageCancel24 } from '../../image/image24';

export default function ADialog( props ) {

    const handleClose = () => {
        props.onClose();
    }

    return ( 

        <div className="dialog">
            <div className="dialog-content">
                <div>
                    <img src={ImageCancel24} alt="" title="Fechar" className="img-dialog-close" onClick={handleClose} />                                      
                </div>
                <p>{props.message}</p>
            </div>
        </div>
      
    );

}


//         <div className='dialog-btn-content' >          <button className='btn-dialog-close' onClick={handleClose} >Ok</button>