import React from 'react';

import './styles.scss';


export default function ADate( props ) {

    const { name, caption, required, data, onChange } = props;
  
    const value = data[name];

    return (
        
        <div className='input-content' >
            <label for={name}>{caption}</label>
            <input 
                type="date"
                id={name} 
                name={name}
                required={required}
                onChange={onChange}
                value={value}
            />
        </div> 
    );

}